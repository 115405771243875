import React from 'react';
import ClientGrid from "./components/client-grid/ClientGrid";
import BigHeader from "../commons/big-header/BigHeader";
import './client.scss';
import firstScreen from "../static/images/navigation/navigation-firstScreen.png";
import navigationMap from "../static/images/navigation/navigation-map.png";
import lastScreen from "../static/images/navigation/navigation-lastScreen.png";
import tabletWithLogo from "../static/images/navigation/navigation-tabletWithLogoVW.png";
import ContactBanner from "../commons/contact-banner/ContactBanner";
import GridFeatures from "./components/grid-features/GridFeatures";

const clientHeaderData = [
    {
        device: "tablet",
        image: tabletWithLogo,
        title: "Volkswagen AG",
        subtitle: "Navigation App",
        text: "We wanted to have an Intelligent traffic control app to avoid traffic jams and shortening waiting times and render the most optimistic route from source to destination based on the custom coordinates provided by the quantum system. MESH Platform enables this solution without any issues making the user experience seamless and efficient from day one."
    }
];

const clientGridData = [
    {
        device: "tablet",
        image: firstScreen,
    },
    {
        device: "tablet",
        image: navigationMap,
    },
    {
        device: "tablet",
        image: lastScreen,
    }
];

const volkswagenFeatures = [1, 15, 23, 24];

const Navigation = () => {
    return (
        <>

            {clientHeaderData.map(data => {
                return (
                    <BigHeader key={data.title}
                               device={data.device}
                               image={data.image}
                               title={data.title}
                               subtitle={data.subtitle}
                               text={data.text}/>
                )
            })}
            <div className='client-grid-container'>
                <div className="container-fluid">
                    <div className="row">
                        {clientGridData.map(data => {
                            return (
                                <ClientGrid key={data.image}
                                            device={data.device}
                                            image={data.image}
                                />
                            )
                        })}
                    </div>
                    <div className='col-12 text-container'>
                        <h2>
                            Transportation Fleet Coordination

                        </h2>
                        <p>
                            Quantum Navigation App shares co-ordinates between transportation fleet vechicles and
                            provide optimised routes for picking-up the passanges.
                        </p>
                    </div>
                </div>
            </div>
            <GridFeatures features={volkswagenFeatures}/>
            <ContactBanner title={"Let's talk about your project..."} buttonText={"Get in contact"} />
        </>
    )
}

export default Navigation;
