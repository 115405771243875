import microservices from "../../static/images/microservices.svg";
import navigation from "../../static/images/navigation/volkswagen-logo.jpg";
import volksbank from "../../static/images/volksbank/profile-volksbank-avatar.png";
import vflLogo from "../../static/images/vfl/vfl-wolfsburg-logo.png";

const features = [
    {
        label: 'Mesh Features',
        link: '/microservices',
        image: microservices
    }
];

const clients = [
    {
        label: 'Volkswagen AG',
        link: '/navigation',
        image: navigation
    },
    {
        label: 'Volksbank',
        link: '/volksbank',
        image: volksbank
    },
    {
        label: 'VFL Wolfsburg',
        link: '/vfl-wolfsburg',
        image: vflLogo
    },
];

export {features, clients};