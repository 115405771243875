import React from 'react';
import "./contactBanner.scss";
import Button from "../button/Button";

const ContactBanner = (props) => {
    return (
        <div id='contact-banner container-fluid'>
            <div className='banner-content row align-items-center'>
                <div className='banner-text col-auto mr-auto'>
                    <h1>{props.title}</h1>
                </div>
                <div className='banner-button col-auto'>
                    <Button text={props.buttonText}
                            link='/contact-us'
                            page={2}/>
                </div>
            </div>
        </div>
    )
};

export default ContactBanner;