// import {faFileSearch} from "@fortawesome/pro-solid-svg-icons";
import {faRoute} from "@fortawesome/free-solid-svg-icons";
import {faShoppingBasket} from "@fortawesome/free-solid-svg-icons";
// import {faCommentsAlt} from "@fortawesome/pro-solid-svg-icons";
import {faIcons} from "@fortawesome/free-solid-svg-icons";
import {faSitemap} from "@fortawesome/free-solid-svg-icons";
import {faUsers} from "@fortawesome/free-solid-svg-icons";
import {faBell} from "@fortawesome/free-regular-svg-icons";
import {faNewspaper} from "@fortawesome/free-regular-svg-icons";
import {faFileUpload} from "@fortawesome/free-solid-svg-icons";
import {faSignInAlt} from "@fortawesome/free-solid-svg-icons";
import {faCalendarTimes} from "@fortawesome/free-solid-svg-icons";
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons";
import {faUtensils} from "@fortawesome/free-solid-svg-icons";
import {faMapMarkedAlt} from "@fortawesome/free-solid-svg-icons";
import {faCashRegister} from "@fortawesome/free-solid-svg-icons";
// import {faCommentsAltDollar} from "@fortawesome/pro-light-svg-icons";
// import {faPersonSign} from "@fortawesome/pro-solid-svg-icons";
//import {faFileInvoiceDollar} from "@fortawesome/free-solid-svg-icons";
// import {faWindowAlt} from "@fortawesome/pro-light-svg-icons";
// import {faSteeringWheel} from "@fortawesome/pro-solid-svg-icons";
import {faIdBadge} from "@fortawesome/free-regular-svg-icons";
import {faTasks} from "@fortawesome/free-solid-svg-icons";
// import {faCommentAltLines} from "@fortawesome/pro-light-svg-icons";
import {faCreditCard} from "@fortawesome/free-solid-svg-icons";

const Features = [
    {
        id: 1,
        icon: faRoute,
        title: "Direction",
        category: "Navigation",
        description: "Feature used to fetch optimal directions between two GeoPoints using Machine Learning.",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
    },
    {
        id: 2,
        // icon: faCommentsAlt,
        title: "Chat",
        category: "Communication",
        description: "Feature that allows users to chat among themselves across an entire organisation or a particular department.",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
    },
    {
        id: 3,
        icon: faSitemap,
        title: "Organization",
        category: "User Management",
        description: "Feature to manage the organisation information and hierarchy across different departments.",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
    },
    {
        id: 4,
        icon: faIcons,
        title: "Content management",
        category: "Content Management",
        description: "Feature to administer, orchestrate and distribute content of a specific requirement or need within an organisation or a particular department or a region.",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
    },
    {
        id: 5,
        icon: faUsers,
        title: "Users-service",
        category: "User Management",
        description: "Service to manage users and user information.",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
    },
    {
        id: 6,
        icon: faBell,
        title: "Notifications",
        category: "Communication",
        description: "Feature to send the push notification to the apps on multiple platforms.",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
    },
    {
        id: 7,
        icon: faShoppingBasket,
        title: "Marketplace",
        category: "Marketplace",
        description: "Feature that allows users to buy and sell objects or post classified Advertisements within a organisation or department.",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
    },
    {
        id: 8,
        // icon: faFileSearch,
        title: "Review-service",
        category: "Communication",
        description: "Feature that enables four-eye check before content gets distributed. Also, can be leveraged for comment reviews.",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
    },
    {
        id: 9,
        icon: faNewspaper,
        title: "News",
        category: "Content Management",
        description: "Feature to deliver daily or important feeds for different applications and targeted users.",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
    },
    {
        id: 10,
        icon: faFileUpload,
        title: "Media",
        category: "Content Management",
        description: "Media service to manage (upload, serve) images, videos.",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
    },
    {
        id: 11,
        icon: faSignInAlt,
        title: "Auth-service",
        category: "User Management",
        description: "API's to authenticate/authorise users, manage roles, rights and permissions.",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
    },
    {
        id: 12,
        icon: faCalendarTimes,
        title: "Leave Management",
        category: "User Management",
        description: "This service used as a management of leaves for employees synchronising with calendar.",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
    },
    {
        id: 13,
        icon: faShoppingCart,
        title: "Order management",
        category: "Order management",
        description: "Feature to order food by choosing different menu items, quantity with order confirmation at disposal.",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
    },
    {
        id: 14,
        icon: faUtensils,
        title: "Cafeteria",
        category: "Content Management",
        description: "Feature to upload, delete, find restaurants nearby and manage information regarding different restaurants.",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
    },
    {
        id: 15,
        icon: faMapMarkedAlt,
        title: "Discovery",
        category: "Location service",
        description: "Discover new services, restaurants, facilities through map service.",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
    },
    {
        id: 16,
        icon: faCashRegister,
        title: "Payment gateway",
        category: "Payments",
        description: "Digital payment wallet to upload and validate payment information from user, and processing it after order is finished.",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
    },
    {
        id: 18,
        // icon: faCommentsAltDollar,
        title: "Driver payouts",
        category: "Payments",
        description: "Provide payouts to drivers, when shift is over. The payout is sent to Driver's bank account.",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
    },
    {
        id: 19,
        icon: faCreditCard,     //faFileInvoiceDollar,
        title: "Billing and invoicing",
        category: "Payments",
        description: "Managing gathering payments from passenger.",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
    },
    {
        id: 20,
        // icon: faWindowAlt,
        title: "Passenger Web UI ",
        category: "Web UI System",
        description: "User interface to enable management and orders from passengers side.",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
    },
    {
        id: 21,
        // icon: faSteeringWheel,
        title: "Driver Management",
        category: "Order management",
        description: "Driver, to accept, decline and postpone order or order requests.",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
    },
    {
        id: 22,
        // icon: faPersonSign,
        title: "Passenger management",
        category: "Order management",
        description: "Passenger order managements, used to request a car and decline requested car.",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
    },
    {
        id: 23,
        icon: faIdBadge,
        title: "Driver Web UI",
        category: "Web UI System",
        description: "Driver side user interface, showing information for a particular driver order, statuses, payouts, etc.",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
    },
    {
        id: 24,
        icon: faTasks,
        title: "Trip management",
        category: "Navigation",
        description: "Enabling trip queue for multiple orders between the various end points.",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
    },
    {
        id: 25,
        // icon: faCommentAltLines,
        title: "Comments",
        category: "Communication",
        description: "Feature to handle the comments on the content like news, advertisements, events, etc.",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
    }
];

export default Features;