import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import features from "../../../../microservices/microservicesData";
import MicroserviceCard from "../../../../commons/microsesrvice-card/MicroserviceCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";


const FeaturesCarousel = () => {
    const SlickButtonFix = ({currentSlide, slideCount, children, ...props}) => (
        <span {...props}>{children}</span>
    );

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        nextArrow: <SlickButtonFix><FontAwesomeIcon icon={faChevronRight} className='slider-arrows' /></SlickButtonFix>,
        prevArrow: <SlickButtonFix><FontAwesomeIcon icon={faChevronLeft} className='slider-arrows' /></SlickButtonFix>,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return(
        <div id="features-carousel">
            <div />
            <Slider {...settings}>
                { features.map(feature => {
                    return (
                        <div key={feature.id} className="carousel-box">
                            <MicroserviceCard icon={feature.icon}
                                              title={feature.title}
                                              description={feature.description}
                                              text={feature.text}
                            />
                        </div>
                    )
                })}
            </Slider>
            <div />
        </div>
    )
}

export default FeaturesCarousel;