import React from 'react';
import Intro from "./sections/intro/Intro";
import ExploreOurFeatures from "./sections/exploreOurFeatures/ExploreOurFeatures";
import Customizable from "./sections/features/customizable";
import Resilent from "./sections/features/resilent";
import Collaboration from "./sections/features/collaboration";
import PlatformFeatures from './sections/platformFeatures/PlatformFeatures';
import GoToTopButton from "./sections/goToTopButton/GoToTopButton";
import ClientTestimonials from "./sections/clientTestimonials/ClientTestimonials";

const Home = () => {
    return (
        <>
            <Intro />
            <ExploreOurFeatures/>
            <div className="features-content">
                <Customizable/>
                <Resilent/>
                <Collaboration/>
            </div>
            <PlatformFeatures />
            <ClientTestimonials />
            <GoToTopButton />
        </>
    );
}

export default Home;