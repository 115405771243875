import React from 'react';
import "./contactUs.scss";
import Button from "../commons/button/Button";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import PhoneCode from './components/PhoneCode';
import loadingPic from "../static/loading.svg";

const warningIcon = <FontAwesomeIcon className={'warning-icon'} icon={faExclamationCircle}/>

const requestOptions = {
    method: 'POST',
    headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'x-api-key': '5c13a519-abfa-42d5-986a-c846fc232632'
    }
};
const reactNodeEnv = process.env.REACT_APP_APP_ENV;

let baseUrl = window.location.origin;

if (reactNodeEnv && reactNodeEnv === 'local') {
    baseUrl = 'http://localhost:5000';
}

if (reactNodeEnv && reactNodeEnv === 'qa') {
    baseUrl = 'https://kongapiqa.hexad.de';
}

class ContactUs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            err: undefined,
            isLoading: false,
            isRegistrationDone: false,
            phone: '',
            code: '+49'
        };

        this.submitData = this.submitData.bind(this);
        this.renderForm = this.renderForm.bind(this);
    }

    submitData(e) {
        e.preventDefault();

        const { phone, code } = this.state;
        const phoneNumber = `${code}${phone}`;
        //console.log(`final phone: ${phoneNumber}`);

        const userForm = {
            name: e.target.elements.first_name.value,
            lastName: e.target.elements.last_name.value,
            email: e.target.elements.email.value,           // "Email not allowed if gmail!? and can not be approved after error message"
            organization: e.target.elements.organization.value,
            phoneNumber
        };

        if (Object.values(userForm).filter(value => value === '').length > 0) {
            this.setState({err: ['All fields are required']});
            return;
        }

        this.setState({isLoading: true});
        fetch(`${baseUrl}/organizations-service/organizations/contact-us`, {
            ...requestOptions,
            body: JSON.stringify(userForm)
        }).then(subRequest => {
            if (subRequest.status !== 200) {
                subRequest.json().then(err => {
                    this.setState({err: [err.message]})
                });
                return;
            }

            this.setState({isRegistrationDone: true});
        }).finally(() => this.setState({isLoading: false}));
    }

    setPhoneFormat = (phone) => {
        if(phone && phone.charAt(0) === '+')
        {
            const newPhone = phone.substr(1);
            this.setState({ phone: newPhone });
        } else {
            this.setState({ phone: phone });
        }
    }

    callback = (code) => {
        this.setState({code});
    }

    renderForm() {
        const { isLoading, phone } = this.state;

        return (
            <form id="signup-form"
                  className={`mt-3 ${isLoading && 'form-loading'}`}
                  onSubmit={this.submitData}
            >
                <div className="row">
                    <div className="col-md-6 col-12">
                        <input type="text" name="first_name" disabled={isLoading} placeholder="First Name"/>
                    </div>
                    <div className="col-md-6 col-12">
                        <input type="text" name="last_name" disabled={isLoading} placeholder="Last Name"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5 col-12">
                        <PhoneCode parentCallback={this.callback} />
                    </div>
                    <PhoneInput placeholder="Phone number"
                                //value={ phone && formatPhoneNumber(phone, 'International') }
                                value={ phone }
                                onChange={ phone => { this.setPhoneFormat(phone) } }
                                country={null}
                                //country="DE"
                                className="phone-number-content col-md-7 col-12"
                                showCountrySelect={false}
                    />
                </div>
                <input type="email" disabled={isLoading} name="email" placeholder="Email" />
                <input type="text" disabled={isLoading} name="organization" placeholder="Organisation" />
                <button type="submit" disabled={isLoading}>
                    {
                        this.state.isLoading
                            ? <img src={loadingPic} alt="Loading..."/>
                            : 'Submit'
                    }
                </button>
            </form>
        )
    }

    renderRegistrationComplete() {
        return (
            <div className="registration-complete">
                <span className="registration-complete__title">
                    <i className="fas fa-check-circle"/>
                    <h2>Thank you.</h2>
                </span>
                <p>
                    We'll contact you soon
                </p>
                <div>
                    <Button link={"/"} text={'Back to home'} page={1}/>
                </div>
            </div>
        )
    }

    render() {
        const {err, isRegistrationDone} = this.state;
        return (
            <div id="contact" className="fullScreenContainer">
                <section className="align-content-middle signup">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-4 mt-5 mr-auto ml-auto p-3" id="get-started">
                                <div className="get-started_form">
                                    {
                                        !isRegistrationDone
                                            ? <h2 className="mb-3 mt-3">Let’s connect</h2>
                                            : <div/>
                                    }
                                    {
                                        !isRegistrationDone ?
                                        !!err &&
                                        <div className='error-message'>
                                            {err.map((er, index) =>
                                                <div className="message-box" key={index}>
                                                    <span className="icon-box">{warningIcon}</span>
                                                    <p>{er}</p>
                                                </div>)
                                            }
                                        </div> : <div/>
                                    }

                                    {
                                        !isRegistrationDone
                                            ? this.renderForm()
                                            : this.renderRegistrationComplete()
                                    }
                                </div>
                                {
                                    !isRegistrationDone
                                        ? <div className="get-started_footer">
                                            By clicking submit Now, you agree to our <a href="./terms-of-service">Terms of
                                            Service</a> and <a href="./privacy-policy">Privacy
                                            Policy</a>
                                        </div>
                                        : <div/>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default ContactUs;
