import React from 'react';
import './button.scss';

const Button = (props) => {

    const buttonColor = (page) => {
        if (page === 'intro') {
            return "button"
        } else if (page === 1) {
            return "contact blue"
        } else if (page === 2) {
            return "contact red"
        } else if (page === 3) {
            return "contact green"
        } else if (page === 4) {
            return "contact yellow"
        }else {
            return "contact blue"
        }
    };

    const handleClick = () => {
        if (!!props.onClick) {
            props.onClick()
        }
    };

    return (
        <div className="row d-flex mt-3" onClick={handleClick}>
            {(props.page > 0 && props.text === "More info") ?
                <div className={buttonColor(props.page)} href={props.link}>{props.text}</div>
                : <a className={buttonColor(props.page)} href={props.link}>{props.text}</a>
            }
        </div>
    )
}

export default Button;