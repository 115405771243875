import React from 'react';
import './microserviceCard.scss';
// import Button from "../button/Button";
import TruncateMarkup from "react-truncate-markup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";

class MicroserviceCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showInformation: false
        }
    }

    showInformation = () => {
        this.setState({
            showInformation: !this.state.showInformation
        });
    };


    render() {
        const {icon, title, description} = this.props;
        // const {showInformation} = this.state;
        return (
            <>
                <div className='microservice-card'>
                    <div className='icon-container'>
                        <div className='icon'>
                            <span>
                                {<FontAwesomeIcon icon={icon} className='microservice-icon'/>}
                            </span>
                        </div>
                    </div>
                    <div className='title'>
                        <TruncateMarkup lines={2}>
                            <h1>{title}</h1>
                        </TruncateMarkup>
                    </div>
                    <div className='description'>
                        <TruncateMarkup lines={5}>
                            <p>{description}</p>
                        </TruncateMarkup>
                    </div>
                    {/*<div className='button-container'>*/}
                    {/*    <Button text='More info'*/}
                    {/*            page={4}*/}
                    {/*            onClick={this.showInformation}/>*/}
                    {/*</div>*/}

                    {/*<div className={showInformation ? 'information-show' : 'information-hidden'} id='more-information'>*/}
                    {/*    <div className='information-more' onClick={this.showInformation}>*/}
                    {/*        <div className='information-content'>*/}
                    {/*            <div className='title'>*/}
                    {/*                <FontAwesomeIcon icon={faChevronLeft} className='microservice-icon'/>*/}
                    {/*                <TruncateMarkup lines={1}>*/}
                    {/*                    <h2>{title}</h2>*/}
                    {/*                </TruncateMarkup>*/}
                    {/*            </div>*/}
                    {/*            <div className='text'>*/}
                    {/*                <TruncateMarkup lines={12}>*/}
                    {/*                    <p>{text}</p>*/}
                    {/*                </TruncateMarkup>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </>
        );
    }
}


export default MicroserviceCard;