import React from 'react';
import './platform-features.scss';
import Button from '../../../commons/button/Button';
import FeaturesCarousel from './components/FeaturesCarousel';

const PlatformFeatures = () => {

    return (
        <section className="container-fluid" id="platform-features">
            <div className="row justify-content-center text-center">
                <h1>Our Platform Features</h1>
            </div>
            <div className="p-3 container-fluid">
                <FeaturesCarousel className="row"/>
            </div>
            <div className="row justify-content-center text-center pb-5">
                <Button text={'learn more'} link={"/microservices"} page={2}/>
            </div>
        </section>
    )
}

export default PlatformFeatures;