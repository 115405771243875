import React, {useState} from 'react';
import './App.css';
import {BrowserRouter as Switch, Route} from "react-router-dom";
import Home from "./home/Home";
import Footer from "./commons/footer/Footer";
import ContactUs from "./contact-us/ContactUs";
import Vfl from "./clients/Vlf";
import Volksbank from "./clients/Volksbank";
import Navigation from "./clients/Navigation";
import TermsOfService from './termsOfService/TermsOfService';
import PrivacyPolicy from './privacyPolicy/PrivacyPolicy';
import Header from "./commons/header/Header";
import Microservices from "./microservices/Microservices";

const App = () => {

    const [state, setState] = useState({
            showMenuIconModal: false
    })

        const switchMenuIconModal = (event) => {
            setState({
                showMenuIconModal: !state.showMenuIconModal
            });
            event.stopPropagation()
        }

        const hideMenuIconModal = () => {
            setState({showMenuIconModal: false});
        };

        const dynamicCss = window.location.pathname === "/" ? "homePage" : "";

        return (
            <Switch>
                <div id='app-website' className={`top-background ${dynamicCss}`} onClick={hideMenuIconModal}>
                    <Header showLogoHeader={dynamicCss !== 'homePage'}
                            showMenuIconModal={state.showMenuIconModal}
                            hideMenuIconModal={hideMenuIconModal}
                            switchMenuIconModal={switchMenuIconModal}/>
                    <Route path="/" exact component={Home}/>
                    <Route path="/vfl-wolfsburg" component={Vfl}/>
                    <Route path="/volksbank" component={Volksbank}/>
                    <Route path="/navigation" component={Navigation}/>
                    <Route path="/microservices" component={Microservices}/>
                    <Route path="/terms-of-service" component={TermsOfService}/>
                    <Route path="/privacy-policy" component={PrivacyPolicy}/>
                    <Route path="/contact-us" component={ContactUs}/>
                    <Footer/>
                </div>
            </Switch>
        );

}

export default App;
