import React from 'react';
import './exploreOurFeatures.scss';

class ExploreOurFeatures extends React.Component {
    render(){
        return (
            <section className="container-fluid" id="explore-features">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h1>Introducing our solution</h1>
                    </div>
                </div>
            </section>
        )
    }
}


export default ExploreOurFeatures;
