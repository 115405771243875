import React from "react";
import Logo from "../../../../commons/logo/Logo";

const AnimatedLogo = () => {

    const TxtRotate = function(el, toRotate, period) {
        this.toRotate = toRotate;
        this.el = el;
        this.loopNum = 0;
        this.period = parseInt(period, 10) || 10;
        this.txt = '';
        this.tick();
        this.isDeleting = false;
    };

    TxtRotate.prototype.tick = function() {
        const i = this.loopNum % this.toRotate.length;
        const fullTxt = this.toRotate[i];

        if (this.isDeleting) {
            this.txt = fullTxt.substring(0, this.txt.length - 1);
        } else {
            this.txt = fullTxt.substring(0, this.txt.length + 1);
        }

        this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';

        const that = this;
        let delta = 70 - Math.random() * 100;

        if (this.isDeleting) { delta /= 15; }

        if (!this.isDeleting && this.txt === fullTxt) {
            delta = this.period;
            this.isDeleting = true;
        } else if (this.isDeleting && this.txt === '') {
            this.isDeleting = false;
            this.loopNum++;
            delta = 100;
        }

        setTimeout(function() {
            that.tick();
        }, delta);
    };

    window.onload = function() {
        const elements = document.getElementsByClassName('txt-rotate');
        for (let i=0; i<elements.length; i++) {
            const toRotate = elements[i].getAttribute('data-rotate');
            const period = elements[i].getAttribute('data-period');
            if (toRotate) {
                new TxtRotate(elements[i], JSON.parse(toRotate), period);
            }
        }
        // INJECT CSS
        const css = document.createElement("style");
        css.type = "text/css";
        css.innerHTML = ".txt-rotate > .wrap";
        document.body.appendChild(css);
    };

    return (
        <div className="animated-logo">
            <div className="row d-flex">
                <Logo />
            </div>
            <br/>
            <div className="row d-flex mt-4 mb-4 animated-text">
                <span
                    className="txt-rotate"
                    data-period="3000"
                    data-rotate='[ "Discover how business agility, cloud engineering, and an open, welcoming culture can improve outcome for our clients.", "The Mesh Platform has in-built modular components, what we call them as core services  which significantly accelerates your digital workplace dexterity with minimum time-to-market.", "Mesh is a one-stop solution or even better, a platform as one size fits all approach that can be tailored to needs of companies, big or small. A MNC Bank or small Sports shop.", "We bring you powerful advantages to navigate your digital transformation.", "Monolithic, linear application designs are giving way to a more loosely coupled integrative approach: the apps and services architecture.", "What are you waiting for? You no longer need to build products or apps from scratch. Enrich and enhance your workplace by using Mesh as the platform to the common digital dexterity pain points and challenges." ]'>
                </span>
            </div>
        </div>
    )
}

export default AnimatedLogo;