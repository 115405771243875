import React, {useState} from 'react';
import './microservices.scss';
import Features from "./microservicesData";
import microservices from "../static/images/microservices.svg"
import BigHeader from "../commons/big-header/BigHeader";
import MicroserviceCard from "../commons/microsesrvice-card/MicroserviceCard";
import ContactBanner from "../commons/contact-banner/ContactBanner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";

const headerData = {
    image: microservices,
    title: "MESH Platform Features",
    texts: [
        "Building a customized application that is tailored to business needs or a monolithic one is no longer a challenge with MESH Platform whether you are a small sports goods shop or a global bank. ",
        "Libraries built within MESH Platform are able to handle service-to-service communication with minimal disruption to operations.",
        "MESH Platform routes requests from one service to the next, optimizing how all the moving parts work together.",
        "MESH Platform with the help of microservices fulfilling the potential of client products by enabling a variety of features from day one."
    ]
};


const Microservices = () => {

    const buttonCategories = 'col-xl-4 col-sm-6';
    const filterIcon = <FontAwesomeIcon icon={faFilter}/>;

    const [showCategories, setShowCategories] = useState(false);
    const [filterActive, setFilterActive] = useState(false);
    const [itemFilter, setItemFilter] = useState(null);

    const switchShowCategories = () => {
        setShowCategories(!showCategories);
        setFilterActive(!filterActive);
    };

    const handleClick = (newFilter) => {
        window.location.href = "#feature-cards-scroll";
        setItemFilter(newFilter);
    };

    const categories = new Set(Features.map((item) => {
        return item.category
    }));

    const itemCategory = [...categories].map((item) => {
        return (
            <li key={item} className={buttonCategories}>
                <button onClick={() => handleClick(item)}
                        className={`itemCategory ${item === itemFilter ? 'selectedItem' : ''}`}>
                    <span>{item}</span>
                </button>
            </li>
        )
    });

    const features = Features.filter((item) => {
        if (itemFilter == null) {
            return true
        } else {
            return item.category === itemFilter
        }
    }).map((item) => {
        return (
            <section className='col-xl-4 col-md-6' key={item.id}>
                <MicroserviceCard icon={item.icon}
                                  title={item.title}
                                  description={item.description}
                                  text={item.text}/>
            </section>
        )
    });

    return (
        <>
            <BigHeader key={headerData.title}
                       device={headerData.device}
                       image={headerData.image}
                       title={headerData.title}
                       subtitle={headerData.subtitle}
                       texts={headerData.texts}/>

            <div className='microservices-container'>

                <div id='categories' className='container-fluid'>
                    <div className='row'>

                        <div className='col-md-2'>
                            <button className={`filterButton ${filterActive ? 'filter-active' : ''}`}
                                    onClick={switchShowCategories}>
                                <span className='filter-icon'>{filterIcon}</span>
                                <span>Filters</span>
                            </button>
                        </div>

                        {showCategories &&
                        (
                            <div id='categories-list' className='col-md-10'>
                                <div className='container-fluid'>
                                    <ul className='row'>
                                        <li className={buttonCategories}>
                                            <button onClick={() => handleClick(null)} className='itemCategory'>
                                                <span className='allItem'>ALL</span>
                                            </button>
                                        </li>
                                        {itemCategory}
                                    </ul>
                                </div>
                            </div>
                        )

                        }


                    </div>
                </div>

                <span id="feature-cards-scroll" />

                <div className='container-fluid'>
                    <div className='row'>
                        {features}
                    </div>
                </div>

            </div>

            <ContactBanner title={"Tell us about your idea..."}
                           buttonText={"Get in contact"}/>

        </>
    )
};

export default Microservices;