import React from 'react';
import Logo from "../logo/Logo";
import './footer.scss';

class Footer extends React.Component {
    render() {
        return (
            <div className="container-fluid" id="footer">
                <div className="row footer-content">
                    <div className="col-lg-4 logo-display">
                        <a href="/" className="footer-logo"><Logo /></a>
                    </div>
                    <div className="col-lg-4 container-fluid footer-list">
                        <div className="row">
                            <h3 className="col-lg-6">Mesh Platform</h3>
                            <ul className="col-lg-6">
                                <li><a href="/">How it Works</a></li>
                                <li><a href="/contact-us">Request a Demo</a></li>
                                <li><a href="/terms-of-service">Terms of Service</a></li>
                                <li><a href="/privacy-policy">Privacy Policy</a></li>
                            </ul>
                        </div>
                    </div>
                    {/*<div className="col-lg-4 container-fluid footer-list">*/}
                    {/*    <div className="row">*/}
                    {/*        <h3 className="col-lg-6">About Company</h3>*/}
                    {/*        <ul className="col-lg-6">*/}
                    {/*            <li><a href="/">Legal Entity</a></li>*/}
                    {/*        </ul>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="col-lg-4 container-fluid footer-list">
                        <div className="row">
                            <h3 className="col-lg-6">About Company</h3>
                            <ul className="col-lg-6 list-text" id="about-company">
                                <li className='title'>Hexad Spain S.L.</li>
                                <li className='direction'>Mallorca 100 Bajos, Barcelona</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;
