import React from 'react';
import customizableImage from "../../../static/easily-customizable-website.svg";

class Customizable extends React.Component {
    render(){
        return (
            <section className="container-fluid" id="customizable">
                <div className="container">
                    <div className="row justify-content-center h-100">
                        <div className="col-md-5 d-flex">
                            <img src={customizableImage} alt="Customizable" className="featured-image"/>
                        </div>
                        <div className="col-md-4 container-content">
                            <span className="splash-content">
                                <h2 className="m-0">Designed with customization in mind.</h2>
                                <p>
                                    Built using a microservice architecture, MESH Platform comes with an extensive feature set as standard, allowing your teams to customize products to meet their needs.
                                </p>
                             </span>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Customizable;
