import React from 'react';
import './goToTopButton.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

const upIcon = <FontAwesomeIcon icon={faChevronUp} style={{width: "40px", height: "40px"}} />

class GoToTopButton extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            display: "displayNone"
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', () =>{
            if (window.pageYOffset > 1024 && this.state.display === "displayNone"){
                this.setState(
                    {
                        display:  "displayBlock"
                    }
                )
            }
            if (window.pageYOffset < 1024 && this.state.display === "displayBlock"){
                this.setState(
                    {
                        display:  "displayNone"
                    }
                )
            }

        });
    }

    render() {
        const { display } = this.state;
        return (
            <>
                <a href="#app-website" className={`${display} go-to-top-container`}>
                    <div className="arrow">
                        {upIcon}
                    </div>
                </a>
            </>
        )
    }
}

export default GoToTopButton;