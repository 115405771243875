import React from 'react';
import './clientGrid.scss';

const ClientGrid = ({device, image, title, text}) => {
    return (
        <section className='col-md-4 client-grid'>
            <div className={`image-container-${device === 'smartphone' ? 'smartphone' : 'tablet'} image-container `}>
                <div className='image' style={{backgroundImage: "url(" + image + ")"}}/>
            </div>
            {device === 'smartphone' ?
                <div className='info-container'>
                    <h2 className='card-title'>{title}</h2>
                    <p className='text'>{text}</p>
                </div>
                : <div className='info-container-tablet' />
            }
        </section>
    );
};


export default ClientGrid;