import React from 'react';
import './logo.scss';

const Logo = () => {
    return (
        <div id="logo">
            <span className="logo-text-mesh"><span className="mesh-decor">Mesh</span></span>
            <span className="logo-text-platform">Platform</span>
        </div>
    );
}

export default Logo;