import React from 'react';
import collaborationImage from "../../../static/Icon-3.svg";
import Button from "../../../commons/button/Button";

class Collaboration extends React.Component {
    render(){
        return (
            <section className="container-fluid" id="collaboration">
                <div className="container">
                    <div className="row justify-content-center h-100">
                        <div className="col-md-5 d-flex">
                            <img src={collaborationImage} alt="Collaboration" className="featured-image"/>
                        </div>
                        <div className="col-md-4 container-content">
                             <span className="splash-content">
                                <h2 className="m-0">Collaborate in real-time.</h2>
                                <p>
                                    Document, store, and share knowledge, allowing your users to collaborate quickly and effectively.
                                </p>
                             </span>
                            <Button link={'/contact-us'} text={'Request a demo'} page={"content"} />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Collaboration;
