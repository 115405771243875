import React from 'react';
import './clientTestimonials.scss';
import Button from "../../../commons/button/Button";

const ClientTestimonialsCard = (data) => {
    const client = data.data;

    return (
        <>
            <a href={client.link} className="clients-card row pt-5 pb-5">
                {(client.id % 2 === 0) ? (
                    <div className="col-md-5 image-container-client">
                        <div className="background-client"
                             style={{backgroundImage: "url(" + client.testimonialImage + ")"}}/>
                    </div>) : null
                }
                <div className="col-md-7">
                    <h2 className="m-0">{client.title}</h2>
                    <h4 className="m-0 pb-2">{client.subtitle}</h4>
                    <p>{client.text}</p>
                    <Button link={client.link} text={'More info'} page={client.id} />
                </div>
                {(client.id % 2 !== 0) ? (
                    <div className="col-md-5 image-container-client">
                        <div className="background-client-reverse"
                             style={{backgroundImage: "url(" + client.testimonialImage + ")"}}/>
                    </div>) : null
                }
            </a>
        </>
    );
}

export default ClientTestimonialsCard;