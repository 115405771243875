import React, {Component} from 'react';
import StickyHeader from "react-sticky-header";
import 'react-sticky-header/styles.css';
import Logo from "../logo/Logo";
import './header.scss';
import HeaderMenu from "./components/header-menu/HeaderMenu";


class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display: false,
            showLogo: props.showLogoHeader
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', () => {
            this.props.hideMenuIconModal();
            this.setState(
                {
                    display: window.pageYOffset > 40
                }
            )
        });
    }


    render() {
        const {display, showLogo} = this.state;
        const {switchMenuIconModal, showMenuIconModal} = this.props;
        return (
            <StickyHeader
                header={
                    <div className="Header_root">
                        <section className={`${display ? 'header-background' : ''} container-fluid`} id="header">
                            <div className="row d-flex flex-row justify-content-md-end">
                                <div className='col-5'>
                                    <a className={`${display || showLogo ? 'show-logo' : 'hidden-logo'}`} id='header-logo' href="/">
                                        <Logo/>
                                    </a>
                                </div>
                                <HeaderMenu switchModal={switchMenuIconModal} showModal={showMenuIconModal}/>
                            </div>
                        </section>
                    </div>
                }
            >
            </StickyHeader>
        );
    }
}


export default Header;
