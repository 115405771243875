import React from 'react';
import ClientGrid from "./components/client-grid/ClientGrid";
import BigHeader from "../commons/big-header/BigHeader";
import './client.scss';
import mobileWithLogo from "../static/images/volksbank/volksbank-mobileWithLogo.png";
import shareUpdates from "../static/images/volksbank/screen1-black.png";
import employees from "../static/images/volksbank/screen2-black.png";
import chat from "../static/images/volksbank/screen3-black.png";
import ContactBanner from "../commons/contact-banner/ContactBanner";
import GridFeatures from "./components/grid-features/GridFeatures.js";

const clientHeaderData = [
    {
        device: "smartphone",
        image: mobileWithLogo,
        title: "Volksbank - International Bank",
        subtitle: "Intranet Social Network",
        text: "MESH Platform helped us to build and establish such Intranet services as Market Place, Newsfeed and Department management for Android, iOS Apps and also for Web application at the same time flawlessly."
    }
];

const clientGridData = [
    {
        device: "smartphone",
        image: shareUpdates,
        title: "Share organization updates",
        text: "Our goal with News Feed is to show you the stories that matter most to you. Your News Feed can be personalized by filter, ever-changing collection of photos, videos, links, and updates from your lovely collegues."
    },
    {
        device: "smartphone",
        image: employees,
        title: "Let your employees sell and buy stuff not quitting from the office",
        text: "You can use Marketplace to buy and sell items within the organisation. You can find and buy things by searching for a specific item or browsing categories."
    },
    {
        device: "smartphone",
        image: chat,
        title: "Internal chat",
        text: "Message your collegues. Our Chat uses your phone's Internet connection to send messages so you can avoid SMS fees."
    }
];

const volksbankFeatures = [7, 4, 6, 25, 3, 9, 10, 11, 5, 12];

const Volksbank = () => {
    return (
        <>
            {clientHeaderData.map(data => {
                return (
                    <BigHeader key={data.title}
                               device={data.device}
                               image={data.image}
                               title={data.title}
                               subtitle={data.subtitle}
                               text={data.text}/>
                )
            })}
            <div className='client-grid-container'>
                <div className='container-fluid'>
                    <div className='row'>
                        {clientGridData.map(data => {
                            return (
                                <ClientGrid key={data.title}
                                            device={data.device}
                                            image={data.image}
                                            title={data.title}
                                            text={data.text}/>
                            )
                        })}
                    </div>
                </div>
            </div>
            <GridFeatures features={volksbankFeatures} />
            <ContactBanner title={"Let's talk about your project..."} buttonText={"Get in contact"} />
        </>
    )
}

export default Volksbank;
