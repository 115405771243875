import React from 'react';
import Button from "../../../commons/button/Button";
import Features from "../../../microservices/microservicesData";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './gridFeatures.scss';

const FeaturesGrid = (props) => {
    const productFeatures = props.features;

    return (
        <div id="features-used">

            <div className='features-content container-fluid'>
                <div className='row'>
                    <div className='features-text col-12'>
                        <h1>Platform Features Used</h1>
                    </div>
                    <div className="features-area col-xl-9">
                        <div className='container-fluid'>
                            <div className="features-services row">
                                {Features.map(feature => {
                                    if (productFeatures.includes(feature.id)) {
                                        return (
                                            <div key={feature.id} className="features-service col-md-2 col-sm-4 col-6">
                                                <div className='features-icon'>
                                                    {<FontAwesomeIcon icon={feature.icon}
                                                                      className='microservice-icon'/>}
                                                </div>
                                                <p>{feature.title}</p>
                                            </div>
                                        )
                                    } else {
                                        return null;
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='features-button col-xl-3'>
                        <Button text={'All features'}
                                link='/microservices'
                                page={4}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeaturesGrid;

