import React from 'react';
import ClientGrid from "./components/client-grid/ClientGrid";
import BigHeader from "../commons/big-header/BigHeader";
import './client.scss';
import mobileWithLogo from "../static/images/vfl/vfl-mobileWithLogo.png";
import walletPhone from "../static/images/vfl/vfl-wallet-phone.png";
import shareMyCarPhone from "../static/images/vfl/vfl-shareMyCar-phone.png";
import snackBarPhone from "../static/images/vfl/vfl-snackBar-phone.png";
import ContactBanner from "../commons/contact-banner/ContactBanner";
import GridFeatures from "./components/grid-features/GridFeatures.js";

const clientHeaderData = [
    {
        device: "smartphone",
        image: mobileWithLogo,
        title: "VFL Wolfsburg - Football club",
        subtitle: "Carpooling and Stadium Catering App",
        text: "The goal of the app was to make our fans trip to the stadium and back more convenient and efficient, also our requirements included additional catering features that should have been available within the same application to make our football fan's life easier. MESH Platform opens us an opportunity to establish a cross-platform application for our football team fans without any issues."
    }
];

const clientGridData = [
    {
        device: "smartphone",
        image: walletPhone,
        title: "The wallet",
        text: "Virtual wallet to buy stuff from the bar, pay trips and be paid."
    },
    {
        device: "smartphone",
        image: shareMyCarPhone,
        title: "Share my trip",
        text: "Share car to go to an event so you can go together and support the team. You'll be helping to reduce traffic jams."
    },
    {
        device: "smartphone",
        image: snackBarPhone,
        title: "Pre orders",
        text: "Avoid lines and get ready to watch the match. Pre order beers and snack and collect them at the counter whenever they're ready."
    }
];

const vflFeatures = [1, 15, 23, 24, 21, 20, 22, 19, 18, 16, 13, 5, 11, 10, 9, 2, 6];

const Vfl = () => {

    return (
        <>

            {clientHeaderData.map(data => {
                return (
                    <BigHeader key={data.title}
                               device={data.device}
                               image={data.image}
                               title={data.title}
                               subtitle={data.subtitle}
                               text={data.text}/>
                )
            })}
            <div className='client-grid-container'>
                <div className="container-fluid">
                    <div className="row">
                        {clientGridData.map(data => {
                            return (
                                <ClientGrid key={data.title}
                                            device={data.device}
                                            image={data.image}
                                            title={data.title}
                                            text={data.text}/>
                            )
                        })}
                    </div>
                </div>
            </div>
            <GridFeatures features={vflFeatures} />
            <ContactBanner title={"Let's talk about your project..."} buttonText={"Get in contact"} />
        </>
    )
}

export default Vfl;
