import React from 'react';
import data from './code';

const dataArray = Object.values(data);

const PhoneCode = ({ parentCallback }) => {

    function focus() {
        [].forEach.call(this.options, function(o) {
            o.textContent = o.getAttribute('data-descr') + ' (' + o.getAttribute('value') + ')';
        });
    }
    function blur() {
        [].forEach.call(this.options, function(o) {
            o.textContent = o.getAttribute('value');
        });
    }
    [].forEach.call(document.querySelectorAll('.shortened-select'), function(s) {
        s.addEventListener('change', function(){this.blur()});
        s.addEventListener('mousedown', function(){this.blur()});
        s.addEventListener('focus', focus);
        s.addEventListener('blur', blur);
        //blur.call(s);
    });

    const isSelected = (element) => {
        element.preventDefault();

        const newCode = element.target.value;
        parentCallback(newCode);
    }

    return (
            <select
                defaultValue="+49"
                className="shortened-select phone-code-content"
                onChange={ element => isSelected(element) }
                autoComplete='nope'
            >
                {dataArray.map((code, index) => {
                    return (
                        <option key={index} data-descr={code.name} value={code.code}>
                            {`${code.name} (${code.code})`}
                        </option>
                    )
                })}
            </select>
    )
}

export default PhoneCode;
