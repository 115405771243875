import React from 'react';
import './bigHeader.scss';

const BigHeader = ({device, image, title, subtitle, text, texts = []}) => {

    const textList = texts.map((item, index) => {
        return <li key={index}>{item}</li>
    });


    return (
        <section className="container-fluid align-content-middle fullScreenContainer" id="big-header">
            <div className="row col-lg-10">
                <div className='col-md-7 align-content-middle'>
                    <div className={`info-container ${texts.length ? 'header-list' : ''}`}>
                        <h1>{title}</h1>
                        <h2>{subtitle}</h2>
                        <p>{text}</p>
                        <ul className={texts ? 'text-list' : 'hidden-list'}>
                            {textList}
                        </ul>
                    </div>
                </div>
                <div className='col-md-5'>
                    <div className={`${device === 'tablet' ? 'tablet' : ''} image `}>
                        <div style={{backgroundImage: "url(" + image + ")"}}/>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default BigHeader;