import React from 'react';
import './headerMenu.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane, faTh} from "@fortawesome/free-solid-svg-icons";
import {clients, features} from "../../HeaderData"

const HeaderMenu = (props) => {
    const {switchModal, showModal} = props;
    const featuresMenu = features.map((feature) => {
        const key = feature.label.toLowerCase().replace(" ", "-");
        return (
            <div className='col-4 option' key={key} id={key}>
                <a href={feature.link}>
                    <div className='image-container'>
                        <div className='image' style={{backgroundImage: "url(" + feature.image + ")"}}/>
                    </div>
                    <label>{feature.label}</label>
                </a>
            </div>
        )
    });
    const clientsMenu = clients.map((client) => {
        const key = client.label.toLowerCase().replace(" ", "-");
        return (
            <div className='col-4 option' key={key} id={key}>
                <a href={client.link}>
                    <div className='image-container'>
                        <div className='image' style={{backgroundImage: "url(" + client.image + ")"}}/>
                    </div>
                    <label>{client.label}</label>
                </a>
            </div>
        )
    });
    const contactIcon = <FontAwesomeIcon icon={faPaperPlane}/>;
    const menuIcon = <FontAwesomeIcon icon={faTh}/>;
    return (
        <>
            <div id='desktop-menu' className="col-7">
                <ul className='desktop-menu-content'>
                    <li className='home-link-container'>
                        <a className='home-link' href="/">Home</a>
                    </li>
                    <li name='header-button' className='menu-icon-container' onClick={switchModal}>
                            <span className='menu-icon'>
                                {menuIcon}
                            </span>
                    </li>
                    <li className='contact-container'>
                        <a className="signUp button-hover-animation" href="/contact-us">
                            <span className='contact-text'>Contact us</span>
                            <span className='contact-icon'>{contactIcon}</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div id='modal-menu' className={showModal ? 'modal-show' : 'modal-hidden'}>
                <div className='container'>
                    <div className='row features-menu'>
                        <div className='col-12 title'>
                            <h2>Platform Features</h2>
                        </div>
                        {featuresMenu}
                    </div>
                    <div className='row clients-menu'>
                        <div className='col-12 title'>
                            <h2>Clients</h2>
                        </div>
                        {clientsMenu}
                    </div>
                </div>
            </div>
        </>
    );
};

export default HeaderMenu;
