import React from 'react';
import Button from "../../../commons/button/Button";
import './intro.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import introImage from "../../../static/Icon-1.svg";
import AnimatedLogo from "./components/AnimatedLogo";

const downIcon = <FontAwesomeIcon icon={faChevronDown} style={{width: "40px", height: "40px"}} />

const Intro = () => {
    return (
        <section className="intro-top-background fullScreenContainer align-content-middle">
            <section className="container" id="intro">
                <div className="row">
                    <div className="col-lg-5 h-lg-100 flex-row">
                        <div className="row pl-4 intro-container">
                            <AnimatedLogo />
                        </div>
                        <div className="row pl-4 mt-4 intro-container">
                            <Button link={"/contact-us"} page={'intro'} text={'Request a demo'} />
                        </div>
                    </div>
                    <div className="col-lg-7 h-lg-100 align-content-middle intro-picture">
                        <img src={introImage} alt="Mesh Platform" className="intro-image"/>
                    </div>
                </div>
            </section>
            <a href="#explore-features" className="button-advance intro-button-advance">
                <div className="arrow">
                    {downIcon}
                </div>
            </a>
        </section>
    )
}

export default Intro;
