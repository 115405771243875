import React from 'react';
import newsImage from "../../../static/auto-resilient-0-downtime.svg";

class Resilent extends React.Component {
    render(){
        return (
            <section className="container-fluid" id="resilent">
                <div className="container">
                    <div className="row justify-content-center h-100">
                        <div className="col-md-4 container-content">
                            <span className="splash-content">
                                <h2 className="m-0">Auto Resilient.<br/>Speed and scalability.</h2>
                                <p>
                                    Deployable across AWS, Azure, Google, Cloud Foundry or your own hosted services, MESH supports rapid one-click deployments and rollbacks.
                                </p>
                            </span>
                        </div>
                        <div className="col-md-5 d-flex justify-content-end">
                            <img src={newsImage} alt="Auto Resilient" className="featured-image"/>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Resilent;
