import './clientTestimonials.scss';
import React from "react";
import ClientTestimonialsCard from "./ClientTestimonialsCard";
import volkswagenLogo from "../../../static/volkswagen-logo.jpg";
import volksbankLogo from "../../../static/profile-volksbank.webp";
import wolfsburgLogo from "../../../static/vfl-wolfsburg-logo.png";
import tabletVW from "../../../static/images/navigation/navigation-tabletWithLogoVW2.png";
import mobileVfl from "../../../static/images/vfl/vfl-mobileWithLogo2.png";
import mobileVolksbank from "../../../static/images/volksbank/volksbank-mobileWithLogo2.png";

const testimonialsData = [
    {
        id: 1,
        link: "/navigation",
        image: volkswagenLogo,
        testimonialImage: tabletVW,
        title: "Volkswagen AG",
        subtitle: "Navigation App",
        text: "We built an Intelligent traffic control app to avoid traffic jams and shortening waiting times and render the most optimistic route from source to destination based on the custom coordinates provided by the quantum system. MESH Platform enabled this solution and made the user experience seamless and efficient from day one."
    },
    {
        id: 2,
        link: "/volksbank",
        image: volksbankLogo,
        testimonialImage: mobileVolksbank,
        title: "Volksbank - International Bank",
        subtitle: "Intranet Social Network",
        text: "MESH Platform helped us to build and establish such Intranet services as Market Place, Newsfeed, Department management for Android, iOS, and also for Web application at the same time flawlessly."
    },
    {
        id: 3,
        link: "/vfl-wolfsburg",
        image: wolfsburgLogo,
        testimonialImage: mobileVfl,
        title: "VFL Wolfsburg - Football club",
        subtitle: "Carpooling and Stadium Catering App",
        text: "The goal of the app was to make the travel for the VFL fans to the stadium more accessible. Also, we wanted to present additional catering features available within the same application to make a football fan's life easier. MESH Platform opens an opportunity to establish a cross-platform application for our VFL fans."
    }
]

const ClientTestimonials = () => {
    return (
        <>
            <section className="container-fluid" id="clients">
                <div className="row justify-content-center text-center pt-5">
                    <h1>Built using MESH Platform</h1>
                </div>
                <div className="row justify-content-center pt-3 pb-5">
                    {testimonialsData.map(data => {
                        return (
                            <ClientTestimonialsCard key={data.id} data={data} />
                        )
                    })}
                </div>
            </section>
        </>
    );
}

export default ClientTestimonials;